<template>
  <div class="widgets-page">
    <v-row no-gutters v-if="!isLoading">
      <v-col cols="12" class="page-header d-flex py-3 align-center">
        <span class="page-title">{{ $t('Product widgets') }}</span>
        <v-spacer></v-spacer>
      </v-col>

      <v-col v-for="i in widgetsList" :key="i.id" cols="12" class="mt-4 d-flex elevation-2 align-center">
        <div class="ml-3 d-flex align-center pointer">
          <div class="image-header mr-3">
            <img width="40px" :src="i.image" alt="" srcset="" />
          </div>
          <div class="ml-4 py-2">
            <h3>{{ i.name }}</h3>
            <div>
              {{ i.desc }}
            </div>
            <!-- <div v-if="i.place">
              Show at:
              <v-chip class="mr-1" small v-if="i.place.cartPage">
                Cart Page
              </v-chip>
              <v-chip class="mr-1" small v-if="i.place.homePage && i.widgetType !== 'cartRecommend'">
                Home Page
              </v-chip>
              <v-chip class="mr-1" small v-if="i.place.productPage || i.widgetType === 'handpickedProduct'">
                Product Page
              </v-chip>
            </div> -->
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-switch v-model="i.activated" value @change="activeWidget(i)"></v-switch>
        <v-btn small color="primary" class="mr-3" outlined @click="gotoApp(i)">Customize</v-btn>
      </v-col>
    </v-row>
    <!--  <v-row>
      <v-col cols="12">
        <v-btn color="primary" @click.stop="drawer = !drawer">More filters </v-btn>
      </v-col>
      <v-col cols="12">
        <Resource :columns="columns" :callback="test" />
      </v-col>
-->
  </div>
</template>

<script>
import { widgetApi } from '@/apis/widget';
import { offerApi } from '@/apis/offers';
export default {
  data() {
    let defaultConfig = {
      activated: false,
      device: 'desktop',
      widgetType: 'bestSeller',
      widgetCustomize: {
        productPrice: {
          align: 'center',
          bold: false,
          fontColor: '#000000',
          fontSize: '14px',
          italic: false,
          underline: false,
          textTitle: 'Title ',
        },
        productName: {
          align: 'center',
          bold: false,
          fontColor: '#000000',
          fontSize: '14px',
          italic: false,
          underline: false,
          textTitle: 'Programmer Label Personalized',
        },
        place: {
          cartPage: false,
          categoryPage: false,
          homePage: false,
          productPage: true,
          page: {
            collectionIds: [],
            productIds: [],
            selectType: 'products',
            isShowAtSpecificPage: false,
          },
        },
        header: {
          align: 'center',
          bold: false,
          fontColor: '#000000',
          fontSize: '25px',
          italic: false,
          underline: false,
          textTitle: 'Handpicked products',
        },
        addCartButton: {
          enabled: true,
          callToAction: {
            align: 'center',
            bold: false,
            fontColor: '#000000',
            fontSize: '12px',
            italic: false,
            underline: false,
            textTitle: 'Title ',
          },
        },
        layout: '1',
        numberOfProducts: {
          productsPerSlide: 6,
          productsToBeShown: 12,
        },
      },
    };
    return {
      defaultConfig,
      isLoading: false,
      fakeImage:
        'https://minio.lattehub.com/img/200/200/resize/610e701311273e0009f1bd32/2021/10/06/lattehub-image-615d6e7cba78eab2e9bc35ed.png',
      //activated
      widgetsList: [
        {
          name: 'Handpicked products',
          id: 1,
          image: 'https://minio.lattehub.com/lattehub/static/20220219/1.png',
          to: 'website.apps.widgets.handpicked',
          desc: 'Recommend products you picked by yourselves.',
          show: ['Product'],
          widgetType: 'handpickedProduct',
          activated: false,
        },
        {
          name: 'Best sellers',
          id: 4,
          image: 'https://minio.lattehub.com/lattehub/static/20220219/2.png',
          to: 'website.apps.widgets.cart-recommen',
          desc: 'Recommend products based on customers browsing history & sales history.',
          show: ['Product'],
          widgetType: 'bestSeller',
          activated: false,
        },
        {
          name: 'Recently viewed & featured products',
          id: 6,
          image: 'https://minio.lattehub.com/lattehub/static/20220219/3.png',
          to: 'website.apps.widgets.cart-recommen',
          desc: 'Recommend products you picked by yourselves.',
          show: ['Product'],
          widgetType: 'recentView',
          activated: false,
        },
        {
          name: 'Who bought this also bought',
          id: 5,
          image: 'https://minio.lattehub.com/lattehub/static/20220219/4.png',
          to: 'website.apps.widgets.cart-recommen',
          desc: 'Recommend products which are most matching with the target product.',
          show: ['Product'],
          widgetType: 'alsoBought',
          activated: false,
        },

        {
          name: 'Cart recommendations',
          id: 2,
          image: 'https://minio.lattehub.com/lattehub/static/20220219/5.png',
          to: 'website.apps.widgets.cart-recommen',
          desc: "Recommend products based on customer's cart.",
          show: ['Home', 'Product', 'Cart'],
          widgetType: 'cartRecommend',
          activated: false,
        },
        {
          name: 'Products from the same collections',
          id: 3,
          image: 'https://minio.lattehub.com/lattehub/static/20220219/6.png',
          to: 'website.apps.widgets.same-collection',
          desc: 'Recommendation products in the same collection with a target product.',
          show: ['Product', 'Cart'],
          activated: false,
          widgetType: 'moreCollection',
        },
        {
          name: 'Pick for you',
          id: 10,
          image: 'https://minio.lattehub.com/lattehub/static/20220219/7.png',
          to: 'website.apps.widgets.pickForYou',
          desc: 'Recommendation products sam title or same niche.',
          show: ['Product'],
          activated: false,
          widgetType: 'pickForYou',
        },
        {
          name: 'More featuring with artwork',
          id: 17,
          image: 'https://minio.lattehub.com/lattehub/static/20220219/7.png',
          to: 'website.apps.widgets.designCode',
          desc: 'More featuring with artwork.',
          show: ['Product'],
          activated: false,
          widgetType: 'designCode',
        },
      ],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async activeWidget(i) {
      await widgetApi.create({
        productWidget: {
          widgetType: i.widgetType,
          activated: i.activated,
        },
      });
      // if (i.widgetType !== 'handpickedProduct') {
      //   await widgetApi.create({
      //     productWidget: {
      //       widgetType: i.widgetType,
      //       activated: i.activated,
      //     },
      //   });
      // } else {
      //   await offerApi.update(i._id, {
      //     activated: i.activated,
      //   });
      // }
    },
    async loadData(params) {
      this.isLoading = true;
      try {
        let res = await widgetApi.getAll({
          widgetTypes: 'cartRecommend,moreCollection,bestSeller,recentView,alsoBought,designCode',
        });

        if (res.data.productWidgets.length < 16) {
          this.createAllWidget();
        }
        if (res.data.productWidgets.length > 0) {
          const uniqByKeepFirst = (a, key) => {
            let seen = new Set();
            return a.filter(item => {
              let k = key(item);
              return seen.has(k) ? false : seen.add(k);
            });
          };

          // function uniqByKeepLast(a, key) {
          //   return [...new Map(a.map(x => [key(x), x])).values()];
          // }

          //

          let removeDuplicate = uniqByKeepFirst(res.data.productWidgets, it => it.widgetType);
          for (let i = 0; i < removeDuplicate.length; i++) {
            let item = removeDuplicate.find(it => it.widgetType == this.widgetsList[i].widgetType);
            if (item) {
              this.widgetsList[i].activated = item.activated;
              this.widgetsList[i].widgetType = item.widgetType;
              this.widgetsList[i]._id = item._id;
              this.widgetsList[i].place = item.widgetCustomize.place;
            }
          }
        }
        console.log('  this.widgetsList', this.widgetsList);
      } catch (error) {}
      this.isLoading = false;
    },
    async createAllWidget() {
      let [
        cartRecommend,
        moreCollection,
        bestSeller,
        recentView,
        alsoBought,
        handpickedProduct,
        pickForYou,
        designCode,
      ] = [
        {
          ...this.defaultConfig,
          widgetType: 'cartRecommend',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Cart recommendations',
            },
            showWith: 'sameTitle',
            place: {
              cartPage: true,
              homePage: true,
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'moreCollection',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'More from {{collection_name}}',
            },
            place: {
              cartPage: true,
              homePage: false,
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'bestSeller',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Store best sellers',
            },
            place: {
              homePage: true,
              cartPage: false,
              productPage: false,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'recentView',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Recently viewed & featured recommendations',
            },
            place: {
              cartPage: true,
              homePage: true,
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'alsoBought',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Who bought this also bought',
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'handpickedProduct',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            place: {
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'pickForYou',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Pick For You',
            },
            showWith: 'sameTitle',
            place: {
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'designCode',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'More featuring this artwork',
            },
            showWith: 'designCode',
            place: {
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
      ];
      //cartRecommend

      let res = await widgetApi.createAll({
        productWidgets: [
          cartRecommend,
          moreCollection,
          bestSeller,
          recentView,
          alsoBought,
          handpickedProduct,
          pickForYou,
          designCode,
        ],
      });
    },
    gotoApp(item) {
      if (item.widgetType !== 'handpicked') {
        this.$router.push({
          name: item.to,
          params: {
            widgetType: item.widgetType,
          },
        });
      } else {
        this.$router.push({
          name: item.to,
        });
      }

      this.$store.commit('setNavigationMini', true);
    },
  },
};
</script>
<style lang="scss">
.image-header {
  width: 40px;
  height: 40px;
  @include flex(center, flex-center);
  border-radius: 5px;
  background: $main-color;
}
.widgets-page {
  line-height: 2.2;
}
</style>
